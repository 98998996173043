import {
  Box,
  Button,
  Card,
  Center,
  Flex,
  Group,
  Loader,
  Modal,
  Radio,
  Select,
  Stack,
  Switch,
  TextInput,
} from '@mantine/core';
import { IconCheck, IconChevronRight } from '@tabler/icons-react';
import { GetSensorResponse } from 'api/actions/get-sensor/get-sensor-response';
import { useApi } from 'api/api-context';
import { BackIcon } from 'components/icons/BackIcon';
import { FavoriteDisabledIcon } from 'components/icons/FavoriteDisabledIcon';
import { FavoriteEnabledIcon } from 'components/icons/FavoriteEnabledIcon';
import useLang from 'lang';
import panic from 'panic';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import theme from 'theme';
import H2SemiBold from 'theme/components/Typography/H2SemiBold';
import H5SemiBold from 'theme/components/Typography/H5SemiBold';
import P1Medium from 'theme/components/Typography/P1Medium';
import P2Medium from 'theme/components/Typography/P2Medium';
import P2Regular from 'theme/components/Typography/P2Regular';
import P4Regular from 'theme/components/Typography/P4Regular';
import H1Medium from 'theme/components/Typography/H1Medium';
import P4Medium from 'theme/components/Typography/P4Medium';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
} from 'chart.js';
import { useDisclosure } from '@mantine/hooks';
import { MinusIcon } from 'components/icons/MinusIcon';
import { PlusIcon } from 'components/icons/PlusIcon';

// Registrácia potrebných komponentov pre Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

type Sensor = GetSensorResponse['sensor'];

/**
 * Detail of a sensor.
 */
export default function SensorDetail() {
  const { lang, _t } = useLang();
  const { getAction } = useApi();
  const [sensor, setSensor] = useState<Sensor | null>(null);
  const { sensorId: sensorIdRaw = '' } = useParams();
  const sensorId = useMemo(() => parseInt(sensorIdRaw), [sensorIdRaw]);
  const [isFavorite, setIsFavorite] = useState(false); // Stav pre sledovanie, či je senzor pridaný medzi obľúbené
  const [selectedTitle, setSelectedTitle] = useState<string | null>('Tento týždeň');
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (sensorId) {
      const getSensor = getAction('GetSensor');

      getSensor({ parameters: { sensorId } })
        .then(({ sensor }) => setSensor(sensor))
        .catch(panic);
    }
  }, [sensorId]);

  /**
   * Handle a click on the favorite button.
   *
   * Toggles the favorite status of the sensor and shows a notification.
   */
  const handleFavoriteClick = () => {
    setIsFavorite((prevIsFavorite) => !prevIsFavorite);

    alert(!isFavorite ? chartData : 'Senzor odstránený z obľúbených');
  };

  // Function to calculate average value based on selected title
  const calculateAverageValue = useMemo(() => {
    if (!sensor || !sensor.chart || !sensor.chart.views) return 0;

    const chartView = sensor.chart.views.find((view) => view.title === selectedTitle);
    if (!chartView || !chartView.data || chartView.data.length === 0) return 0;

    const total = chartView.data.reduce((acc, item) => acc + (item.value ?? 0), 0);
    return (total / chartView.data.length).toFixed(2); // Return average value rounded to two decimal places
  }, [sensor, selectedTitle]);

  /**
   * Get the range for a selected title.
   *
   * @param title The title of the chart view
   * @returns The range of the chart view if found, otherwise an empty string
   */
  const getRangeForSelectedTitle = (title: string) => {
    if (!sensor || !sensor.chart || !sensor.chart.views) return '';

    const chartView = sensor.chart.views.find((view) => view.title === title);
    return chartView ? chartView.range : '';
  };

  const chartData = useMemo(() => {
    if (!sensor || !sensor.chart || !sensor.chart.views) {
      return null;
    }

    const chartViews = sensor.chart.views.find((view) => view.title === selectedTitle);
    if (!chartViews || !chartViews.data) {
      return null;
    }

    const dataValues = chartViews.data.map((item) => item.value ?? 0);

    return {
      labels: chartViews.data.map((item) => item.labelShort),
      datasets: [
        {
          barPercentage: 0.6,
          label: 'Blue Bars',
          data: dataValues,
          backgroundColor: '#551FFF',
          hoverBackgroundColor: '#1F212A',
          borderRadius: 5,
          borderSkipped: false,
          stack: 'Stack 0',
        },
      ],
    };
  }, [sensor, selectedTitle]);

  const backgroundBar = {
    id: 'backgroundBar',
    beforeDraw: (chart: ChartJS<'bar'>) => {
      const {
        data,
        ctx,
        chartArea: { top, width, height },
        scales: { x },
      } = chart;

      const length = data?.labels?.length ?? 0;

      ctx.save();

      const segment = width / length;
      const barWidth = segment * 0.49;

      ctx.fillStyle = '#EEF1F5';
      ctx.beginPath();
      for (let i = 0; i < length; i++) {
        ctx.roundRect(x.getPixelForValue(i) - barWidth / 2, top, barWidth, height, 5);
      }
      ctx.fill();
    },
  };

  const chartOptions = {
    scales: {
      x: {
        ticks: {
          color: theme.colors?.gray?.[7],
        },
        grid: {
          drawBorder: false,
          display: false,
        },
        border: {
          display: false,
        },
      },

      y: {
        // beginAtZero: true,
        ticks: {
          color: theme.colors?.gray?.[7],
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        filter: function (tooltipItem: TooltipItem<'bar'>) {
          return (tooltipItem.dataset.label ?? '') !== 'Gray Background';
        },
        callbacks: {
          title: function (context: TooltipItem<'bar'>[]) {
            if (!sensor || !sensor.chart || !sensor.chart.views) return '';
            const chartViews = sensor.chart.views.find((view) => view.title === selectedTitle);
            if (!chartViews || !chartViews.data || context.length === 0) return '';
            const index = context[0].dataIndex;
            const data = chartViews.data[index];
            const value = data.value ?? 0;
            const suffix = sensor.mainBox?.unit ?? '';
            return `${value} ${suffix}`;
          },

          label: function (context: TooltipItem<'bar'>) {
            if (!sensor || !sensor.chart || !sensor.chart.views) return '';
            const chartViews = sensor.chart.views.find((view) => view.title === selectedTitle);
            if (!chartViews || !chartViews.data) return '';
            const index = context.dataIndex;
            const data = chartViews.data[index];
            const date = data.labelLong ?? 'N/A';
            return `${date}`;
          },
        },
      },

      legend: {
        display: false,
      },
    },
  };

  if (!sensor) {
    return (
      <Center style={{ height: '100vh' }}>
        <Flex direction="column" align="center" gap="1rem">
          <Loader size="lg" color="#551FFF" />
          <H2SemiBold>{_t('Načítavanie senzoru')}</H2SemiBold>
        </Flex>
      </Center>
    );
  }

  const titles = sensor.chart?.views?.map((view) => view.title) || [];

  return (
    <Stack bg="gray.0" py={20} px={16} mih="100vh">
      <Flex justify="space-between" align="center" mb={6} gap={16}>
        <span onClick={() => window.history.back()} style={{ cursor: 'pointer' }}>
          <BackIcon />
        </span>

        <span style={{ textAlign: 'center' }}>
          <H5SemiBold>{sensor?.name}</H5SemiBold>
        </span>
        {isFavorite ? (
          <span onClick={handleFavoriteClick}>
            <FavoriteEnabledIcon />
          </span>
        ) : (
          <span onClick={handleFavoriteClick}>
            <FavoriteDisabledIcon />
          </span>
        )}
      </Flex>
      <Card p={20} radius={10}>
        <Stack gap={20}>
          <Group align="center" justify="center">
            <img src={sensor?.mainBox?.icon} alt={sensor?.name} width={46} />
            <span>
              <H2SemiBold>
                {new Intl.NumberFormat(lang, { style: 'decimal' }).format(sensor?.mainBox?.value ?? 0)}{' '}
                {sensor?.mainBox?.unit}
              </H2SemiBold>
            </span>
          </Group>
          <Stack gap={4} ta="center">
            <P4Regular color="gray.7">
              <span>{sensor?.mainBox?.headline}</span>
            </P4Regular>
            <P4Regular color="gray.7">
              <span>{sensor?.mainBox?.subHeadline}</span>
            </P4Regular>
          </Stack>
        </Stack>
      </Card>

      <Stack gap={16}>
        <P2Medium color="gray.7">Upozornenia</P2Medium>
        <Card py={8} px={16} radius={10}>
          <Stack gap={8} py={8}>
            {sensor?.alerts?.map((alert) => (
              <Group key={alert.title} gap={16}>
                <Stack gap={0} w="calc(100% - 80px)" onClick={open}>
                  <P1Medium color="gray.12">{alert.title}</P1Medium>
                  <P2Regular color="gray.7">{alert.active ? '{{ params }}' : _t('Disabled')}</P2Regular>
                </Stack>
                <Switch
                  checked={alert.active}
                  color="#551FFF"
                  size="lg"
                  styles={{ track: { width: '56px', height: '32px' } }}
                />
              </Group>
            ))}
          </Stack>
          <Stack gap={8} mt={8} py={8}>
            {sensor?.alerts?.map((alert) => (
              <Group key={alert.title} gap={16}>
                <Stack gap={0} w="calc(100% - 40px)">
                  <P1Medium color="gray.12">{alert.title}</P1Medium>
                  <P2Regular color="gray.7">{alert.active ? '{{ params }}' : _t('Disabled')}</P2Regular>
                </Stack>
                <IconChevronRight size={24} color={theme.colors?.gray?.[7]} />
              </Group>
            ))}
          </Stack>
        </Card>
      </Stack>
      <Modal
        opened={opened}
        onClose={close}
        fullScreen
        title="Nastavenia upozornení"
        styles={{
          body: { background: '#F3F4F5', height: '90%' },
          header: { background: '#F3F4F5', height: '10%' },
          title: { margin: '0 auto', fontWeight: 600, color: '#0D0E14' },
          close: { margin: 0, color: '#0D0E14' },
        }}
      >
        <Flex direction="column" justify={'space-between'} h="100%">
          {sensor?.alerts?.map((alert) => (
            <div>
              {alert.options?.map((option) => (
                <Stack bg="white" p={16} key={alert.title} style={{ borderRadius: '0.625rem' }} mb={20}>
                  <Radio.Group onChange={() => {}}>
                    <Flex direction={'column'} mt="xs" w="100%">
                      <div>
                        {option.type === 'select' ? (
                          <>
                            <P1Medium pb={16}>{option.label}</P1Medium>
                            {option.options?.map((selectOption) => (
                              <Radio
                                size="md"
                                py={16}
                                label={selectOption.label}
                                key={selectOption.value}
                                value={selectOption.value}
                                styles={{ root: { borderTop: '1px solid #E0E4EC' } }}
                                color="primary.0"
                              />
                            ))}
                          </>
                        ) : null}
                      </div>
                      <div>
                        {option.type === 'number' ? (
                          <>
                            <P1Medium pb={16}>{option.label}</P1Medium>
                            <Flex py={16} gap={16} justify="space-between" align="center">
                              <span onClick={() => (option.value ?? 0) - 1} style={{ cursor: 'pointer' }}>
                                <MinusIcon />
                              </span>
                              <TextInput
                                variant="tertiary"
                                radius={10}
                                styles={{
                                  input: {
                                    border: '1px solid #E0E4EC',
                                    height: '48px',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                  },
                                }}
                                value={`${option.value ?? 0} ${option.suffix}`}
                                readOnly
                                placeholder={`${option.value ?? 0} ${option.suffix}`}
                              />
                              <span onClick={() => (option.value ?? 0) + 1} style={{ cursor: 'pointer' }}>
                                <PlusIcon />
                              </span>
                            </Flex>
                          </>
                        ) : null}
                      </div>
                    </Flex>
                  </Radio.Group>
                </Stack>
              ))}
            </div>
          ))}
          <Button variant="primary" size="md" leftSection={<IconCheck size={24} />}>
            Uložiť
          </Button>
        </Flex>
      </Modal>
      <Stack gap={16}>
        <P2Medium color="gray.7">Štatistika</P2Medium>
        <Card p={20} radius={10}>
          <Flex gap={12} mb={20}>
            <Box w="50%">
              <P4Regular color="gray.7">Priemerná spotreba </P4Regular>
              <H1Medium>
                {calculateAverageValue} {sensor?.mainBox?.unit}
              </H1Medium>
              <P4Medium>{getRangeForSelectedTitle(selectedTitle || '')}</P4Medium>
            </Box>
            <Box w="50%">
              <Select
                variant="tertiary"
                radius={10}
                styles={{ input: { border: '1px solid #E0E4EC', height: '40px' } }}
                value={selectedTitle}
                onChange={(value) => setSelectedTitle(value)}
                placeholder="Vyberte časový rozsah"
                data={titles
                  .filter((title): title is string => title !== undefined)
                  .map((title) => ({
                    value: title,
                    label: title,
                  }))}
                nothingFoundMessage="Žiadne možnosti"
                searchable
              />
            </Box>
          </Flex>

          {chartData ? (
            <Bar data={chartData} options={chartOptions} plugins={[backgroundBar]} />
          ) : (
            <p>No data available</p>
          )}
        </Card>
      </Stack>

      {/*<Card p={20}>
        <pre>{JSON.stringify(sensor, null, 2)}</pre>
      </Card>*/}
    </Stack>
  );
}
