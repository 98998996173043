import Dashboard from 'pages/dashboard/Dashboard';
import Instructions from 'pages/instructions/Instructions';
import InstructionsDetail from 'pages/instructions/InstructionsDetail';
import Faq from 'pages/faq/Faq';
import MyDocuments from 'pages/my-documents/MyDocuments';
import Helpdesk from 'pages/helpDesk/HelpDesk';
import HelpdeskDetail from 'pages/helpDesk/HelpDeskDetail';
import Ticket from 'pages/helpDesk/Ticket';
import House from 'pages/house/House';
import Ecv from 'pages/house/Ecv';
import EcvAdd from 'pages/house/EcvAdd';
import EcvEdit from 'pages/house/EcvEdit';
import { Route, Routes } from 'react-router-dom';

import {
  DASHBOARD_PATH,
  INSTRUCTIONS_PAGE_PATH,
  INSTRUCTIONS_DETAIL_PAGE_PATH,
  MYDOCUMENTS_PAGE_PATH,
  FAQ_PAGE_PATH,
  HELPDESK_PAGE_PATH,
  HELPDESK_DETAIL_PAGE_PATH,
  TICKET_PAGE_PATH,
  HOUSE_PAGE_PATH,
  ECV_PAGE_PATH,
  ECV_ADD_PAGE_PATH,
  ECV_EDIT_PAGE_PATH,
  SENSOR_DETAIL_PAGE_PATH,
} from 'routes/paths';
import SensorDetail from 'pages/sensors/SensorDetail';

/**
 * All routes in the app must be registered here.
 */
export default function AppRoutes() {
  return (
    <Routes>
      {/* Dashboard */}
      <Route path={DASHBOARD_PATH.original} element={<Dashboard />} />
      {/* MyDocuments */}
      <Route path={MYDOCUMENTS_PAGE_PATH.original} element={<MyDocuments />} />
      {/* Instructions */}
      <Route path={INSTRUCTIONS_PAGE_PATH.original} element={<Instructions />} />
      {/* Instructions detail*/}
      <Route path={INSTRUCTIONS_DETAIL_PAGE_PATH.original} element={<InstructionsDetail />} />
      {/* FAQ*/}
      <Route path={FAQ_PAGE_PATH.original} element={<Faq />} />
      {/* Helpdesk*/}
      <Route path={HELPDESK_PAGE_PATH.original} element={<Helpdesk />} />
      {/* Helpdesk detail*/}
      <Route path={HELPDESK_DETAIL_PAGE_PATH.original} element={<HelpdeskDetail />} />
      {/* Ticket*/}
      <Route path={TICKET_PAGE_PATH.original} element={<Ticket />} />
      {/* House*/}
      <Route path={HOUSE_PAGE_PATH.original} element={<House />} />
      {/* ECV*/}
      <Route path={ECV_PAGE_PATH.original} element={<Ecv />} />
      {/* ECV add*/}
      <Route path={ECV_ADD_PAGE_PATH.original} element={<EcvAdd />} />
      {/* ECV edit*/}
      <Route path={ECV_EDIT_PAGE_PATH.original} element={<EcvEdit />} />
      {/* Sensor detail */}
      <Route path={SENSOR_DETAIL_PAGE_PATH.original} element={<SensorDetail />} />
    </Routes>
  );
}
